import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"

function DoNotSellMyPersonalInformation() {
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  //const [message, setMessage] = useState("")
  const [request, setRequest] = useState("CCPA Do Not Sell")

  const [thanks, setThanks] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [thanks])

  const submitContactForm = event => {
    event.preventDefault()
    const url =
      "https://7vtrzwg0yg.execute-api.us-west-2.amazonaws.com/isn-www-ccpa-request?" +
      "firstname=" +
      firstname +
      "&lastname=" +
      lastname +
      "&email=" +
      email +
      "&address=" +
      address +
      "&request=donotsell"
    let encodedurl = encodeURI(url)
    fetch(encodedurl).then(function () {
      setThanks(true)
    })
  }

  return (
    <div>
      <Helmet
        title="Do Not Sell My Personal Information - California - ISN"
        defer={false}
      />

      <SEO title="Do Not Sell My Personal Information - California - ISN" description="If you are a California resident, you can opt out of the sale of your personal information by providing the following information." />

      <Container>
        <Header />
      </Container>
        <Container className={'legacy-page-spacer'} fluid>
        <Row style={{ paddingTop: "100px" }}>
          <Col sm={12}>
            <h1
              style={{
                textAlign: "center",
                fontSize: "120%",
                fontWeight: "bold",
              }}
            >
              Do Not Sell My Personal Information - California
            </h1>
          </Col>
          {!thanks ? (
            <Col sm={12}>
              <br />
              <div className="entry-content-wrapper clearfix">
                <p style={{ fontSize: "110%" }}>
                If you are a California resident, you can opt out of the sale of your personal information by providing the following information. Please note that you will not be opted out from transactions that the CCPA does not deem to be sales. Requests may be made only by or on behalf of a current resident of California.
                </p>
              </div>
              <Form onSubmit={submitContactForm}>

                <br />
                <p style={{ fontSize: "110%", fontWeight: "bold" }}>
                  Your information:
                </p>
                <div>
                  <Form.Group controlId="firstname">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="firstname"
                      defaultValue={firstname}
                      onBlur={e => setFirstname(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="lastname">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="lastname"
                      defaultValue={lastname}
                      onBlur={e => setLastname(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="address"
                      defaultValue={address}
                      onBlur={e => setAddress(e.target.value)}
                    />
                  </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    defaultValue={email}
                    onBlur={e => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="request">
                  <Form.Control
                    type="hidden"
                    defaultValue={request}
                    onBlur={e => setRequest(e.target.value)}
                  />
                </Form.Group>
                </div>

                <br /><br/>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    firstname === "" ||
                    lastname === "" ||
                    email === ""
                  }
                >
                  Submit
                </Button>
              </Form>
            </Col>
          ) : (
            <Col sm={12} style={{ textAlign: "center" }}>
              <br />
              <p style={{ fontSize: "120%" }}>
                Your request has been received! Please contact us at
                help@inspectionsupport.com if additional help is needed.
              </p>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </div>
  )
}

export default DoNotSellMyPersonalInformation
